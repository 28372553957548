import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";
import { DiscussionEmbed } from "disqus-react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/share";
import Checkout from "../components/checkout";
import "../css/service.css";
import Testimonial from "../components/testimonial";

export default class service extends Component {
  render() {
    const data = this.props.data.contentfulService;
    const testimonials = this.props.data.allContentfulTestimonialsMoom;

    const siteurl = this.props.data.contentfulSiteInformation.siteUrl + "/";
    const twiteerhandle = this.props.data.contentfulSiteInformation
      .twiteerHandle;
    const socialConfigss = {
      site: {
        siteMetadata: { siteurl, twiteerhandle },
      },
      title: data.title,
      slug: data.slug,
    };

    return (
      <Layout>
        <SEO
          title={data.title}
          keywords={[`moom`, `maternidad`, `crianza`, `${data.title}`]}
        />
        <div className="site-container blog-post">
          <div className="container">
            {data.featuredImage ? (
              <div style={{ display: "flex" }} className="ctaHero">
                <Img
                  className="feature-img"
                  fixed={data.featuredImage.fluid}
                  objectFit="cover"
                  style={{ top: "0", left: "0" }}
                />
                {data.ctaType === "buttonRedirect" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <h2>{data.title} by Gabriela Gerez</h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <h4>Por solo $500 MXN</h4>
                      <button
                        className="btn btn-primary ctaButton"
                        style={{
                          marginLeft: "30px",
                          height: "70px",
                          width: "160px",
                          borderRadius: "5%",
                          alignSelf: "center",
                        }}
                      >
                        <a href={data.ctaActionField.ctaActionField}>
                          {data.ctaMessage.ctaMessage}
                        </a>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="ctaActionField"
                    dangerouslySetInnerHTML={{
                      __html: data.ctaActionField?.childMarkdownRemark.html,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="no-image"></div>
            )}

            <div className="details">
              <h1 className="title">{data.title}</h1>
              <span className="date">
                <i class="fas fa-calendar-alt"></i>{" "}
                {moment(data.createdAt).format("LL")}
              </span>
              <div
                className="pitch"
                dangerouslySetInnerHTML={{
                  __html: data.pitch?.childMarkdownRemark.html,
                }}
              />
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                dangerouslySetInnerHTML={{
                  __html: data.bulletitem?.childMarkdownRemark.html,
                }}
              />
              {data.ctaType === "buttonRedirect" ? (
                <button
                  className="btn btn-primary ctaButton"
                  // style={{
                  //   height: "70px",
                  //   width: "160px",
                  //   borderRadius: "5%",
                  //   alignSelf: "center",
                  //   marginLeft: "450px",
                  //   marginBottom: "100px",
                  //   marginTop: "50px",
                  // }}
                >
                  <a href={data.ctaActionField.ctaActionField}>
                    {data.ctaMessage.ctaMessage}
                  </a>
                </button>
              ) : (
                <div
                  className="ctaActionField"
                  dangerouslySetInnerHTML={{
                    __html: data.ctaActionField?.childMarkdownRemark.html,
                  }}
                />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description?.childMarkdownRemark.html,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: data.aboutme?.childMarkdownRemark.html,
                }}
              />
              <Testimonial data={testimonials}></Testimonial>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: data.testimonials?.childMarkdownRemark.html,
                }}
              /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: data.payment?.childMarkdownRemark.html,
                }}
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="ctaButtonDiv">
                  {data.ctaType === "buttonRedirect" ? (
                    <button
                      className="btn btn-primary ctaButton"
                      // style={{
                      //   height: "70px",
                      //   width: "160px",
                      //   borderRadius: "5%",
                      //   alignSelf: "center",
                      //   marginLeft: "450px",
                      //   marginBottom: "100px",
                      //   marginTop: "50px",
                      // }}
                    >
                      <a href={data.ctaActionField.ctaActionField}>
                        {data.ctaMessage.ctaMessage}
                      </a>
                    </button>
                  ) : (
                    <div
                      className="ctaActionField"
                      dangerouslySetInnerHTML={{
                        __html: data.ctaActionField?.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </div>
              </div>
              <hr />
              {data.slug ===
              "circulo-de-mamas-en-busqueda-de-una-crianza-consciente" ? (
                <Checkout />
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query singlePostQueryAndSinglePostQuery($slug: String!) {
    contentfulService(slug: { eq: $slug }) {
      id
      title
      slug
      featuredImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      ctaMessage {
        ctaMessage
      }
      ctaType
      ctaActionField {
        ctaActionField
        childMarkdownRemark {
          html
        }
      }
      bulletitem {
        childMarkdownRemark {
          html
        }
      }
      pitch {
        childMarkdownRemark {
          html
        }
      }
      aboutme {
        childMarkdownRemark {
          html
        }
      }
      payment {
        childMarkdownRemark {
          html
        }
      }
      testimonials {
        childMarkdownRemark {
          html
        }
      }

      createdAt
    }
    contentfulSiteInformation {
      siteUrl
      twiteerHandle
    }
    allContentfulTestimonialsMoom {
      edges {
        node {
          name
          subTitle
          description {
            childMarkdownRemark {
              html
            }
          }
          avatarImage {
            fluid(maxWidth: 200) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
